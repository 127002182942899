<template>
    <alert-error v-if="maintenanceMode">
        {{ $t("maintenance_mode.description") }}
    </alert-error>
</template>

<script setup lang="ts">
const maintenanceMode = useState<boolean | null>("maintenance", (): boolean | null => null);

const {
    public: {
        api: { baseUrl },
    },
} = useRuntimeConfig();
const route = useRoute();

const refresh = async () => {
    const { error } = await useFetch(`${baseUrl}status`);
    maintenanceMode.value = error.value?.statusCode === 503 || route.hash === "#maintenance";

    if (maintenanceMode.value) {
        setTimeout(refresh, 10000);
    }
};

refresh();
</script>
